<template>
    <div
      class="pl-3"
    >
      <v-card
        class="py-6 px-6 mt-3"
        rounded="lg"
        elevation="0"
        width="100%"
      >
        <v-row>
          <v-col flex>
            <div
              class="back-to-projects"
              @click="handleBackClick"
            >
              <v-icon color="blue">
                mdi-chevron-left
              </v-icon>
              <p class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold">
                {{ $t('Back to Milestone Info') }}
              </p>
            </div>
          </v-col>
          <v-col
            v-if="showAddUsersView"
            cols="auto"
            class="pointer"
            @click="closeShowAddUsersView"
          >
            <v-icon>mdi-close</v-icon>
          </v-col>
        </v-row>
        <v-row>
        <v-col flex>
        <p class="text-start font-weight-bold fs-24px mb-0 text-theme-base">
            {{ $t('testActivities') }}
        </p>  
        <div class="mt-4 d-flex">
          <v-chip
            :class="{ 'blue--text': tableTestType === 'plans' }"
            width="200px"
            :color="tableTestType === 'plans' ? 'blue-light' : 'gray-light'"
            label
            @click="changeTestType('plans')"
          >
            <div class="px-2"
            :class="{ 'fw-semibold': tableTestType === 'plans',
            'font-weight-medium': tableTestType !== 'plans'}"
            >
              {{ $t('testPlans') }} <span class="ml-1">{{ getActivePlanItemsCount }}</span>
            </div>
          </v-chip>
          <div class="ml-2">
            <v-chip
              :class="{ 'blue--text': tableTestType === 'runs' }"
              width="200px"
              :color="tableTestType === 'runs' ? 'blue-light' : 'gray-light'"
              label
              @click="changeTestType('runs')"
            >
              <div class="px-2"
              :class="{ 'fw-semibold': tableTestType === 'runs',
              'font-weight-medium': tableTestType !== 'runs'}"
              >
                {{ $t('testRuns') }} <span class="ml-1">{{ getActiveRunItemsCount }}</span>
              </div>
            </v-chip>
          </div>
        </div>
        </v-col>
        </v-row>
    </v-card>
    <v-card
        class="py-6 px-6 mt-3"
        rounded="lg"
        elevation="0"
      >
        <template v-if="showAddUsersView">
          <div class="fs-24px text-start font-weight-bold">{{ selectedTestType }}</div>
          <div class="mt-6 d-flex">
            <v-chip
              :class="{ 'blue--text': tableFilter === 'all' }"
              width="200px"
              :color="tableFilter === 'all' ? 'blue-light' : 'gray-light'"
              label
              @click="changeFilter('all')"
            >
              <div class="font-weight-bold px-2">
                {{ $t('all') }} <span class="ml-2">{{ isTableTypePlans ? planFilterCount :  runFilterCount }}</span>
              </div>
            </v-chip>
            <div class="ml-2">
              <v-chip
                :class="{ 'blue--text': tableFilter === 'selected' }"
                width="200px"
                :color="tableFilter === 'selected' ? 'blue-light' : 'gray-light'"
                label
                @click="changeFilter('selected')"
              >
                <div class="font-weight-bold px-2">
                  {{ $t('selected') }} <span class="ml-2">{{ isTableTypePlans ? selectedPlanLength :  selectedRunLength }}</span>
                </div>
              </v-chip>
            </div>
          </div>
          <v-row class="align-center mt-3">
            <v-col
              cols="6"
              sm="6"
              class="py-0"
            >
            <div class="d-flex flex-row justify-start align-center">
              <v-responsive
                class="ma-0"
                max-width="344"
              >
                <v-text-field
                  v-model="searchFilter"
                  class="text-field mr-3 mt-0 rounded-lg field-theme custom-prepend pa-0"
                  :placeholder="$t('searchByName')"
                  height="40"
                  background-color="#F9F9FB"
                  hide-details
                >
                  <template v-slot:prepend-inner>
                    <SearchIcon />
                  </template>
                </v-text-field>
              </v-responsive>
              <ProjectUserFilter @applyFilters="applyFilters" />
            </div>
            </v-col>
            <v-col
              cols="6"
              sm="6"
              class="d-flex justify-end"
            >
              <v-menu
                left
                content-class="custom_ele rounded-lg shadow-theme"
                :close-on-content-click="false"
                min-width="191"
                nudge-bottom="50"
              >
                <template v-slot:activator="{ on }">
                <v-sheet
                  color="#F2F4F7"
                  width="40px"
                  class="d-flex align-center justify-center cursor-pointer v-sheet-theme"
                  height="40px"
                  rounded="lg"
                  :outlined="true"
                  v-on="on"
                >
                  <GearIcon />
                </v-sheet>
                </template>
                <v-list
                  flat
                >
                  <v-list-item class="font-weight-regular text-start ml-4 mb-4 mt-2">
                    {{ $t('Columns') }}
                  </v-list-item>
                  <v-list-item
                    v-for="(header, index) in filteredMenuHeaders"
                    :key="index"
                    class="custom-menu-item"
                  >
                    <v-checkbox
                      v-model="header.isSelected"
                      :value="header.isSelected"
                      :true-value="true"
                      :false-value="false"
                      class="ma-0 pa-0 field-theme"
                      :ripple="false"
                      off-icon="icon-checkbox-off"
                      on-icon="icon-checkbox-on"
                    >
                      <template v-slot:label>
                        <div class="custom-color-0c111d font-inter custom-text-14">
                          {{ header.text }}
                        </div>
                      </template>
                    </v-checkbox>
                  </v-list-item>
                  <v-list-item
                    class="pl-5 mt-2 fw-semibold px-0 fs-14px"
                    :ripple="false"
                    :class="{ 'text-theme-primary': runHasUnchecked || planHasUnchecked,
                    'text-theme-disabled': !runHasUnchecked || !planHasUnchecked
                   }"
                    @click="handleColumnReset()"
                  >
                    <div
                      class="custom-text-12 font-weight-semibold font-inter custom-color-d0d5dd"
                      :class="{ 'blue--text font-weight-semibold ': isColumnFilter }"
                    >
                      {{ $t('Restore default') }}
                    </div>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <v-row
            v-if="isAnyFilterApplied"
            class="mt-3"
          >
            <v-chip
              v-for="role in this.appliedFilters.roles"
              :key="role.id"
              color="gray-light"
              class="ma-2 gray-ish--text font-weight-semi-bold"
              label
            >
              {{ $t('role') }}: {{ role.name }}
            </v-chip>
            <v-chip
              v-for="project in this.appliedFilters.projects"
              :key="project.id"
              color="gray-light"
              class="ma-2 gray-ish--text font-weight-semi-bold"
              label
            >
              {{ $t('project') }}: {{ project.name }}
            </v-chip>
            <v-chip
              v-for="tag in this.appliedFilters.tags"
              :key="tag.id"
              color="gray-light"
              class="ma-2 gray-ish--text font-weight-semi-bold"
              label
            >
              {{ $t('project') }}: {{ tag.title }}
            </v-chip>
            <v-btn
              class="text-capitalize mt-1"
              color="blue"
              text
              @click="clearAll"
            >
              {{ $t('clearAll') }}
            </v-btn>
          </v-row>
  
          <MileatoneTestPlanTable
          v-if="tableTestType === 'plans'"
          :plans-data="filteredTestPlans"
          :table-filter="tableFilter"
          :filtered-headers="filteredPlanHeaders"
          :filtered-items="filteredTestPlans"
          />

        <MilestoneTestRunTable
        v-if="tableTestType === 'runs'"
            :runs-data="filteredTestRuns"
            :table-filter="tableFilter"
            :filtered-headers="filteredRunHeaders"
            :filtered-items="filteredTestRuns"
        />
        </template>
  
        <v-row
          justify="end"
          class="mt-4"
        >
          <v-col cols="12">
            <v-flex class="mt-6 d-sm-flex justify-end">
              <v-btn
                v-if="showAddUsersView"
                background-color="#F2F4F7"
                height="40px"
                depressed
                class="text-capitalize fw-semibold rounded-lg black--text mr-4 mt-2"
                :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
                @click="openCreateTest"
              >
                <v-icon
                  class="mr-1"
                  size="16"
                >
                  mdi-plus
                </v-icon> {{ isTableTypePlans ? $t('Create new test plan') : $t('Create new test run') }}
              </v-btn>
              <v-btn
                v-if="showAddUsersView && toBeSelectedRunLength && tableFilter === 'all'
                || showAddUsersView && toBeSelectedPlanLength && tableFilter === 'all'"
                depressed
                color="primary"
                height="40px"
                class="text-capitalize fw-semibold rounded-lg white--text mr-4 mt-2"
                :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
                @click="handleAddClick"
              >
                {{ $t('projects.create_project.add') }} {{ `(${isTableTypePlans ? toBeSelectedPlanLength : toBeSelectedRunLength})` }}
              </v-btn>
              <v-btn
                v-if="showAddUsersView && toBeSelectedRunLength && tableFilter === 'selected'
                || showAddUsersView && toBeSelectedPlanLength && tableFilter === 'selected'"
                depressed
                color="primary"
                height="40px"
                class="text-capitalize fw-semibold rounded-lg white--text mr-4 mt-2"
                :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
                @click="handleRemoveClick"
              >
                {{ $t('projects.create_project.remove') }} {{ `(${isTableTypePlans ? toBeSelectedPlanLength : toBeSelectedRunLength})` }}
              </v-btn>
              <v-btn
                v-if="!showAddUsersView || (showAddUsersView && !toBeSelectedRunLength && !toBeSelectedPlanLength)"
                depressed
                color="primary"
                height="40px"
                class="text-capitalize fw-semibold rounded-lg white--text mt-2"
                :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
                :disabled="isCreateDisabled"
                @click="createMilestone"
              >
                {{ $t('create Milestone') }}
              </v-btn>
            </v-flex>
          </v-col>
        </v-row>

      </v-card>
      <ProjectDiscardDialog
        v-model="showConfirmBackDialog"
        :title="$t('milestone.close_testrun_view.title')"
        :content="$t('milestone.close_testrun_view.content')"
        @close="handleCloseClick"
        @handleConfirmClick="handleConfirmClick"
      />
      <RemoveTestActivitiesDialog
        v-model="showConfirmRemoveDialog"
        :title="$t('milestone.close_remove_dialog.title', {name: getTestName})"
        :content="$t('milestone.close_remove_dialog.title', {name: getTestName})"
        :content_part2="$t('milestone.close_remove_dialog.content_part1', {name: getTestName})"
        :btn_label="$t('milestone.close_remove_dialog.btn_label')"
        :milestone_name="getMilestoneName"
        color="primary"
        @close="handleCloseRemoveClick"
        @handleConfirmClick="handleConfirmRemoveClick()"
      />
      <AddTestActivitiesDialog
        v-model="showConfirmAddDialog"
        :title="$t('milestone.close_add_dialog.title', {name: getTestName})"
        :content="$t('milestone.close_add_dialog.title', {name: getTestName})"
        :content_part2="$t('milestone.close_add_dialog.content_part1', {name: getTestName })"
        :btn_label="$t('milestone.close_add_dialog.btn_label')"
        :milestone_name="getMilestoneName"
        color="primary"
        @close="handleCloseAddClick"
        @handleConfirmClick="handleConfirmAddClick()"
      />
    </div>
  </template>
  
  <script>
  import ProjectDiscardDialog from '@/components/Project/ProjectDiscardDialog.vue';
  import MilestoneTestRunTable from '@/components/Milestone/MilestoneTestRunTable.vue';
  import MileatoneTestPlanTable from '@/components/Milestone/MilestoneTestPlanTable.vue';
  import ProjectUserFilter from '@/components/Milestone/MilestoneTestRunFilter.vue';
  import RemoveTestActivitiesDialog from '@/components/Milestone/MilestoneEditRemoveDialog.vue';
  import AddTestActivitiesDialog from '@/components/Milestone/MilestoneEditAddDialog.vue';
  import { showErrorToast } from '@/utils/toast';
  import { runStateMap, MilestoneTestRunTableHeader, MilestoneTestPlanTableHeader } from '@/constants/grid.js';
  import { createNamespacedHelpers, mapGetters } from 'vuex';
  import runsService from '@/services/api/run';
  import SearchIcon from '@/assets/svg/search-icon.svg';
  import GearIcon from '@/assets/svg/gear.svg';

  const { mapActions, mapState } = createNamespacedHelpers('milestone');

  const  { mapState: mapRunState, mapActions: mapRunActions } = createNamespacedHelpers('run');
  const { mapState: mapPlanState, mapActions: mapPlanActions } = createNamespacedHelpers('plan');
  
  let testRuns;
  
  export default {
    name: 'ProjectCreate',
    components: {
      ProjectDiscardDialog,
      MilestoneTestRunTable,
      MileatoneTestPlanTable,
      ProjectUserFilter,
      RemoveTestActivitiesDialog,
      AddTestActivitiesDialog,
      SearchIcon,
      GearIcon,
    },
    data() {
      return {
        isCreateDisabled: false,
        imageSrc: '',
        testRunsList: [],
        form: {
          projectName: '',
          description: '',
          users: [],
        },
        tableTestType: 'plans',
        isColumnFilter: false,
        showConfirmBackDialog: false,
        showConfirmRemoveDialog: false,
        showConfirmAddDialog: false,
        showAddUsersView: true,
        toBeSelectedUser: [],
        selectedTestRuns: [],
        selectedTestPlans: [],
        tableFilter: 'all',
        testRuns: [],
        testPlans: [],
        searchFilter: '',
        appliedFilters: {
          roles: [],
          projects: [],
          tags: [],
        },
        mainFilters: {
          selectedRoles: [],
          testCases: [0, 1000],
          users: [0, 100],
          selectedTags: [],
          dateRange: {
            start: '',
            end: '',
          },
          progress: [0, 100],
        },
        runHeaders: MilestoneTestRunTableHeader,
        planHeaders: MilestoneTestPlanTableHeader,
      };
    },
    computed: {
      ...mapGetters({
        currentAccount: 'user/currentAccount'
      }),
      ...mapRunState({
      runItems: 'items'
    }),
    ...mapPlanState({
      planItems: 'items'
    }),
    ...mapState(["selectedMilestone"]),
    getMilestoneName() {
      return this.selectedMilestone.name;
    },
    getActivePlanItems() {
      return this.planItems?.filter((plan) => !plan.archivedAt);
    },
    getActiveRunItems() {
      return this.runItems?.filter((run) => !run.archivedAt);
    },
    getActivePlanItemsCount() {
      return this.getActivePlanItems.length;
    },
    getActiveRunItemsCount() {
      return this.getActiveRunItems.length;
    },
      imageStyle() {
        return this.imageSrc ? { backgroundImage: `url(${this.imageSrc})` } : {};
      },
      selectedTestType() {
      const testType = {
        plans: this.$t('testPlans'),
        runs: this.$t('testRuns')
      }
      return testType[this.tableTestType];
    },
    toBeSelectedRunLength() {
      return this.filteredTestRuns?.filter((run) => run.toBeSelected).length;
    },
    toBeSelectedPlanLength() {
      return this.filteredTestPlans?.filter((plan) => plan.toBeSelected).length;
    },
    selectedRunLength() {
      return this.filteredTestRuns?.filter((run) => run.selected).length;
    },
    selectedPlanLength() {
      return this.filteredTestPlans?.filter((plan) => plan.selected).length;
    },
    runFilterCount() {
      return this.getActiveRunItemsCount - this.selectedRunLength;
    },
    planFilterCount() {
      return this.getActivePlanItemsCount - this.selectedPlanLength;
    },
    filteredRunHeaders() {
      return this.runHeaders?.filter((header) => header.isSelected);
    },
    filteredPlanHeaders() {
      return this.planHeaders?.filter((header) => header.isSelected);
    },
    filteredMenuHeaders() {
      return this.tableTestType === 'plans' ? 
      this.planHeaders?.filter((header) => header.text != 'Actions') : 
      this.runHeaders?.filter((header) => header.text != 'Actions');
    },
    isTableTypePlans() {
      return this.tableTestType === 'plans';
    },
    getTestName() {
      return this.isTableTypePlans ? this.$t('testPlans').toLowerCase() : this.$t('testRuns').toLowerCase();
    },
    runHasUnchecked() {
      return this.filteredRunHeaders.some(item => !item.isSelected);
    },
    planHasUnchecked() {
        return this.filteredPlanHeaders.some(item => !item.isSelected);
    },
    applyMainFilters() {
      return (items) => {
        const { selectedRoles, testCases, dateRange, selectedTags, users } = this.mainFilters;

        return items.filter(item => {
          if (selectedRoles.length > 0 && !selectedRoles.includes(item.priority)) {
            return false;
          }
          if (item.testcases < testCases[0] || item.testcases > testCases[1]) {
            return false;
          }
          if (dateRange.start && dateRange.end) {
            const itemDate = new Date(item.creationdate);
            const startDate = new Date(dateRange.start);
            const endDate = new Date(dateRange.end);
            if (itemDate < startDate || itemDate > endDate) {
              return false;
            }
          }
          if (selectedTags.length > 0 && !selectedTags.includes(item.tag)) {
            return false;
          }
          if (item.progress < users[0] || item.progress > users[1]) {
            return false;
          }
          return true;
        });
      };
    },
    filteredTestRuns() {
      return this.filteredItems(this.testRuns);
    },

    filteredTestPlans() {
      return this.filteredItems(this.testPlans);
    },
    isAnyFilterApplied() {
      return this.appliedFilters.roles.length || this.appliedFilters.projects.length || this.appliedFilters.tags.length;
    },
    filteredItems() {
      return (items) => {
        if (this.searchFilter && !this.mainFilter) {
          return items.filter(item => this.matchesFilter(item));
        } else if (this.mainFilter && !this.searchFilter) {
          return this.applyMainFilters(items);
        } else if (this.mainFilter && this.searchFilter) {
          const mainFiltered = this.applyMainFilters(items);
          return mainFiltered.filter(item => this.matchesFilter(item));
        }
        return items;
      };
    },
      milestones() {
        let filtered = this.testRunsList.filter((item) => item.customFields.runstatus === this.filter);
  
        return filtered.map((item) => ({
          ...item,
          processedStatus: this.staticValues(item, runStateMap, 'New'),
        }));
      },
    },
  
    watch: {
      currentOrg: 'refreshData',
      filteredMilestones(newValue) {
        if (newValue === 'SEARCH_BACKEND') {
          this.searchRuns();
        }
      },
      mounted() {
        this.refreshData();
      },
    },
    mounted() {

    },
    async created() {
      const handle = this.$route.params.handle;
      testRuns = runsService(this.$api);
      await this.getAllTestRuns({ handle, projectKey: this.$route.params.key, perPage: 99999, currentPage: 0 });
      await this.getAllTestPlans({ handle, perPage: 99999, currentPage: 0, key: this.$route.params.key });
      this.copyTestRunItems();
      this.copyTestPlanItems();
    },
    methods: {
      ...mapActions(['add']),
      ...mapRunActions(['getAllTestRuns']),
      ...mapPlanActions(['getAllTestPlans']),
      createMilestone() {
        this.isCreateDisabled = true;
        let payload = this.selectedMilestone;
        payload.customFields.testRuns = this.selectedTestRuns;
        payload.customFields.testPlans = this.selectedTestPlans;
        payload.runIds = this.selectedTestRuns.map((e) => e.uid)
  
        this.add({ swal: this.$swal, handle: this.$route.params.handle, projectKey: this.$route.params.key, payload })
          .then(() => {
            this.$router.push({
              name: 'Milestones',
              params: {
                handle: this.$route.params.handle,
                key: this.$route.params.key
              },
            });
          })
          .catch((error) => {
            this.isCreateDisabled = false;
            console.error('Failed to create Test Run:', error);
          });
      },
      openCreateTest() {

        if(this.isTableTypePlans) {
          this.$router.push({
            name: 'TestPlanCreate',
            params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key
            },
            query: {
              activeAddMilestone: 'true'
            },
          });

        } else {
          this.$router.push({
            name: 'TestRunCreate',
            params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key
            },
            query: {
              activeAddMilestone: 'true'
            },
          });

        }
      },
      copyTestRunItems() {
        this.testRuns = this.getActiveRunItems;
      },
      copyTestPlanItems() {
        this.testPlans = this.getActivePlanItems;
      },
      openFileDialog() {
        this.$refs.fileInput.click();
      },
      handleFileChange(event) {
        const file = event.target.files[0];
        this.previewImage(file);
      },
      handleDrop(event) {
        const file = event.dataTransfer.files[0];
        this.previewImage(file);
      },
      previewImage(file) {
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imageSrc = e.target.result;
          };
          reader.readAsDataURL(file);
        }
      },
      removeImage() {
        this.imageSrc = '';
      },
      handleBackClick() {
        this.showConfirmBackDialog = true;
      },
      handleCloseClick() {
        this.showConfirmBackDialog = false;
      },
      handleConfirmClick() {
        this.showConfirmBackDialog = false;
        this.$router.replace({
          name: 'MilestoneCreate',
          params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key
          },
          query: {
            activeEdit: 'true'
          }
        });
      },
      handleRemoveClick() {
        this.showConfirmRemoveDialog = true;
      },
      handleConfirmRemoveClick() {
        this.showConfirmRemoveDialog = false;
        this.removeTestActivities();
      },
      handleCloseRemoveClick() {
        this.showConfirmRemoveDialog = false;
      },
      handleAddClick() {
        this.showConfirmAddDialog = true;
      },
      handleConfirmAddClick() {
        this.showConfirmAddDialog = false;
        this.addTestActivities();
      },
      handleCloseAddClick() {
        this.showConfirmAddDialog = false;
      },
      closeShowAddUsersView() {
        this.showAddUsersView = false;
        this.toBeSelectedUser = [];
        this.$router.push({
          name: 'MilestoneCreate',
          params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key
          },
        });
      },
      changeFilter(filter) {
        this.tableFilter = filter;
      },
      changeTestType(type) {
        this.tableTestType = type;
      },
      addItems(items, selectedItems) {
        items.forEach((item) => {
          if (item.toBeSelected) {
            selectedItems.push(item);
            this.$set(item, 'selected', true);
            this.$set(item, 'toBeSelected', false);
          }
        });
      },
      addTestActivities() {
        if (this.isTableTypePlans) {
          this.addItems(this.testPlans, this.selectedTestPlans);
        } else {
          this.addItems(this.testRuns, this.selectedTestRuns);
        }
      },
      removeItems(items, selectedItems) {
        items.forEach((item) => {
          if (item.toBeSelected) {
            const index = selectedItems.findIndex((selectedItem) => selectedItem.uid === item.uid);
            if (index >= 0) {
              selectedItems.splice(index, 1);
            }
            this.$set(item, 'selected', false);
            this.$set(item, 'toBeSelected', false);
          }
        });
      },
      removeTestActivities() {

        if (this.isTableTypePlans) {
          this.removeItems(this.testPlans, this.selectedTestPlans);
        } else {
          this.removeItems(this.testRuns, this.selectedTestRuns);
        }
        
      },
      updateUser(user) {
        const index = this.testRuns.findIndex((item) => item.id === user.id);
        if (index !== -1) {
          this.$set(user, 'selected', this.tableFilter === 'all');
          this.$set(user, 'toBeSelected', false);
        }
      },
      clearAll() {
        this.appliedFilters = {
          roles: [],
          projects: [],
          tags: [],
        };
      },
      handleColumnReset() {
       const headers = this.isTableTypePlans ? this.planHeaders : this.runHeaders;
       headers.forEach((header) => {
         header.isSelected = true;
       });
      },
      applyFilters(filters) {
        this.mainFilters = filters;
      },
      staticValues(item, obj, defultValue) {
        return obj[item.customFields.state] || obj[defultValue];
      },
      matchesFilter(item) {
        const lowerCaseFilter = this.searchFilter.toLowerCase();
  
        const nameMatch = item.name.toLowerCase().includes(lowerCaseFilter);
  
        return nameMatch;
      },
      async searchRuns() {
        if (this.searchFilter) {
          await this.$store.dispatch('run/searchTestRun', {
            handle: this.currentOrg.handle,
            text: this.searchFilter,
            perPage: this.perPage,
            currentPage: this.currentPage,
          });
        } else {
          this.refreshData();
        }
      },
      async refreshData() {
        this.getMilestones(this.$route.params.handle);
      },
      getMilestones(handle) {
        this.get({ handle: handle, projectKey: this.$route.params.key });
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .back-to-projects {
    display: flex;
    cursor: pointer;
    width: max-content;
  }
  
  .search_input {
    width: 100%;
  
    @media screen and (min-width: 600px) {
      width: 300px;
    }
  }
  
  .project-logo {
    border-radius: 50%;
    border: 2px dashed grey;
    width: 150px;
    height: 150px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    cursor: pointer;
    transition: border-color 0.3s;
    background-size: cover;
    background-position: center;
    position: relative;
  }
  
  .project-logo:hover {
    border-color: #2196f3;
  }
  
  .hovering .edit-icon,
  .hovering .delete-icon {
    display: block;
  }
  
  .edit-icon,
  .delete-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) translateX(-30px);
    display: none;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
  }
  
  .delete-icon {
    margin-left: 60px;
  }
  
  .project-logo:hover .edit-icon,
  .project-logo:hover .delete-icon {
    display: block;
  }
  </style>
  